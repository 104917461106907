import { render, staticRenderFns } from "./Violations.vue?vue&type=template&id=135c1516&scoped=true&"
import script from "./Violations.vue?vue&type=script&lang=js&"
export * from "./Violations.vue?vue&type=script&lang=js&"
import style0 from "./Violations.vue?vue&type=style&index=0&id=135c1516&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "135c1516",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCardActions,VDataTable,VDialog,VTextField})
